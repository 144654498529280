import { Action, State, StateContext } from '@ngxs/store';
import { RecordEmptyStateModel } from '@store/mobile/record-empty/record-empty.model';
import {
  AddPackageRecordEmpty,
  ResetPackageRecordEmpty,
  ResetRecordEmpty,
  ScanPackageRecordEmpty,
} from '@store/mobile/record-empty/record-empty.actions';
import { Injectable } from '@angular/core';

@State<RecordEmptyStateModel>({
  name: 'recordEmpty',
  defaults: {
    scannedPackages: [],
  },
})
@Injectable()
export class RecordEmptyState {
  @Action(ScanPackageRecordEmpty)
  public setCurrentPackage(ctx: StateContext<RecordEmptyStateModel>, { packageItem }: ScanPackageRecordEmpty): void {
    ctx.patchState({
      currentPackage: packageItem,
    });
  }

  @Action(AddPackageRecordEmpty)
  public addBox(ctx: StateContext<RecordEmptyStateModel>, { packageItem }: AddPackageRecordEmpty): void {
    const state = ctx.getState();
    const scannedPackages = [...state.scannedPackages, packageItem];

    ctx.patchState({
      ...state,
      scannedPackages,
    });
  }

  @Action(ResetPackageRecordEmpty)
  public resetPackage(ctx: StateContext<Partial<RecordEmptyStateModel>>): void {
    ctx.patchState({
      currentPackage: null,
    });
  }

  @Action(ResetRecordEmpty)
  public reset(ctx: StateContext<Partial<RecordEmptyStateModel>>): void {
    ctx.setState({
      scannedPackages: [],
    });
  }
}
